import { useMixpanel } from 'custom/hooks'
import { useEffect } from 'react'

const useAppOpenEventTrigger = () => {
  const callMixpanel = useMixpanel()
  useEffect(() => {
    if (!sessionStorage.getItem('is_new_visit')) {
      callMixpanel('App Open', {}, (res) => {
        if (res.trackMixpanelEvent) {
          sessionStorage.setItem('is_new_visit', 'true')
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useAppOpenEventTrigger
