import {
  DbSyncLogStatusEnum,
  DbSyncStatusUpdateDocument,
  IsDbSyncInProgressDocument,
} from '__gql__/graphql'
import { useQuery, useSubscription } from '@apollo/client'
import { useCurrentUser } from '@curbside-health-inc/hooks'
import { Alert } from '@mui/material'
import useCurrentUserPolicy from 'hooks/useCurrentUserPolicy'
import React, { useMemo } from 'react'

const DbSyncAlert: React.FC = () => {
  const { currentUser } = useCurrentUser()
  const userPolicy = useCurrentUserPolicy()
  const { data, refetch } = useQuery(IsDbSyncInProgressDocument, {
    skip: !userPolicy?.canSyncDb,
  })
  const subData = useSubscription(DbSyncStatusUpdateDocument, {
    skip: !userPolicy?.canSyncDb,
    onData(res) {
      if (res.data.data?.dbSyncStatusUpdate?.dbSyncLog.status === DbSyncLogStatusEnum.Synced) {
        setTimeout(() => {
          setHideAlert(true)
        }, 5000)
      }
      refetch()
    },
  })
  const [hideAlert, setHideAlert] = React.useState(false)

  const syncMessage = useMemo(() => {
    if (subData.data?.dbSyncStatusUpdate?.dbSyncLog.status === DbSyncLogStatusEnum.Synced) {
      return 'Db Sync completed.'
    } else if (subData.data?.dbSyncStatusUpdate?.dbSyncLog.status === DbSyncLogStatusEnum.Failed) {
      return 'Db Sync failed.'
    }
    return 'Db Sync in progress.'
  }, [subData.data])

  const syncStatus = useMemo(() => {
    switch (subData.data?.dbSyncStatusUpdate?.dbSyncLog.status) {
      case DbSyncLogStatusEnum.Synced:
        return 'success'
      case DbSyncLogStatusEnum.Failed:
        return 'error'
      default:
        return 'info'
    }
  }, [subData.data])

  if (!currentUser) {
    return <></>
  }
  if (!userPolicy?.canSyncDb) {
    return <></>
  }

  return (
    <>
      {(data?.isDbSyncInProgress || subData.data?.dbSyncStatusUpdate?.dbSyncLog) && !hideAlert && (
        <Alert severity={syncStatus} onClose={() => setHideAlert(true)}>
          {syncMessage}
        </Alert>
      )}
    </>
  )
}

export default DbSyncAlert
