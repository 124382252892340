import { styled } from '@mui/material'
import { CSSProperties } from 'react'

interface ImgProps extends CSSProperties {
  bgColor?
  sx?: object
}

const Img = styled('img')<ImgProps>(
  ({
    height,
    width,
    borderRadius,
    marginBottom,
    marginRight,
    bgColor,
    objectFit,
    top,
    left,
    position,
    border,
    boxShadow,
    marginLeft,
    display,
    maxWidth,
    minHeight,
    maxHeight,
    fontSize,
    padding,
    color,
    minWidth,
    sx,
  }: ImgProps) => ({
    height,
    width,
    marginBottom,
    borderRadius,
    objectFit,
    marginRight,
    backgroundColor: bgColor,
    top,
    left,
    position,
    border,
    boxShadow,
    maxWidth,
    maxHeight,
    marginLeft,
    minHeight,
    fontSize,
    display,
    padding,
    color,
    minWidth,
    ...sx,
  }),
)

export default Img
