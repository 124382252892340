import { useCurrentUser, useStopImpersonation } from '@curbside-health-inc/hooks'
import React from 'react'
import { StyledImpersonationAlertRoot, StyledImpersonationButton } from 'shared/StyledComponents'

const ImpersonationAlert: React.FC = () => {
  const { currentUser } = useCurrentUser()
  const { stopImpersonation } = useStopImpersonation()
  if (!currentUser) {
    return <></>
  }
  if (!currentUser?.isImpersonated) {
    return <></>
  }

  return (
    <StyledImpersonationAlertRoot>
      Impersonation mode is active. You are currently logged in as {currentUser?.email}.{' '}
      <StyledImpersonationButton variant='text' onClick={() => stopImpersonation()}>
        Stop impersonating
      </StyledImpersonationButton>
    </StyledImpersonationAlertRoot>
  )
}

export default ImpersonationAlert
