import { useReactiveVar } from '@apollo/client'
import { useCurrentUser } from '@curbside-health-inc/hooks'
import {
  closeNotification,
  displayNotification,
  setNotification,
} from '@curbside-health-inc/reactive-vars'
import { Close } from '@mui/icons-material'
import { Alert, Button, Grow, IconButton, Snackbar } from '@mui/material'
import UserSettingsScreen from 'components/UserSettings/UserSettingsScreen'
import React, { useEffect, useState } from 'react'
export default function NotificationAlert() {
  const {
    open: openNotification,
    text: notificationText,
    type: notificationType,
  } = useReactiveVar(setNotification)
  const { currentUser } = useCurrentUser()
  const [openUserSettingsModal, setOpenUserSettingsModal] = useState(false)
  const showAccountSettingsNotification = currentUser?.name === 'first_name last_name'

  useEffect(() => {
    if (currentUser && showAccountSettingsNotification) {
      displayNotification(
        'In order to use Curbside effectively, please update your profile',
        'info',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const handleCloseSnackbar = (event?: any, reason?: any) => {
    if (reason === 'clickaway') {
      return
    }
    setNotification(closeNotification)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={openNotification}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        TransitionComponent={Grow}
        data-testid='notification'
      >
        <Alert
          variant='filled'
          onClose={handleCloseSnackbar}
          severity={notificationType}
          data-testid='notification-alert'
          sx={{ color: (theme) => theme.palette.common.white }}
          action={
            <>
              {showAccountSettingsNotification &&
                notificationText ===
                  'In order to use Curbside effectively, please update your profile' && (
                  <Button
                    size='small'
                    onClick={(e) => {
                      setOpenUserSettingsModal(true)
                      handleCloseSnackbar(e, 'btnClick')
                    }}
                  >
                    Click Here
                  </Button>
                )}
              <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={() => handleCloseSnackbar()}
                data-testid='close-notification'
              >
                <Close fontSize='small' />
              </IconButton>
            </>
          }
        >
          {notificationText}
        </Alert>
      </Snackbar>
      {currentUser && openUserSettingsModal && (
        <UserSettingsScreen
          onClose={() => setOpenUserSettingsModal(false)}
          currentTab='My Account'
          openState={openUserSettingsModal}
        />
      )}
    </>
  )
}
