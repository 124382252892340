import { Button, Grid, MenuItem, styled, Typography } from '@mui/material'

const HeaderTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: 10,
  paddingLeft: 8,
  fontWeight: 600,
  fontSize: '24px',
  letterSpacing: '0.01071em',
  lineHeight: 1.43,
  color: theme.palette.grey.grey7,
}))

const ContentBodyGrid = styled(Grid)({
  overflow: 'auto',
  padding: '10px',
  width: '75%',
})

const SectionDivider = styled(Grid)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.primary}`,
}))

const MenuTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '0.7rem',
  padding: '5px 15px',
  color: theme.palette.grey.grey4,
  textTransform: 'uppercase',
}))

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  fontWeight: 400,
  borderRadius: 6,
  margin: '3px 0px',
  color: theme.palette.grey.grey7,
}))

const LineDivider = styled(Grid)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.primary}`,
  margin: '10px 18px',
}))

const LogoutButton = styled(Button)(({ theme }) => ({
  padding: '6px 16px',
  fontSize: '1rem',
  fontWeight: 400,
  color: theme.typography.color.red,
}))

export {
  ContentBodyGrid,
  HeaderTypography,
  LineDivider,
  LogoutButton,
  MenuItemStyled,
  MenuTitle,
  SectionDivider,
}
