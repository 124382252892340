import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material'
import { Grid, GridProps, IconButton, styled } from '@mui/material'

interface StyledOpenAndClosedProps extends GridProps {
  useOpenSettingsSidebar?
}

interface StyledColPrimaryProps {
  useOpenSettingsSidebar?
  useMediaQuery?: boolean
  theme?
}

const StyledClosedButton = styled(IconButton)(({ theme }: any) => ({
  position: 'absolute',
  right: '0px',
  top: '0px',
  borderRadius: '50%',
  display: 'flex',
  padding: '4px',
  color: theme.palette.grey.grey7,
  border: `1px solid ${theme.palette.border.primary}`,
  margin: '15px 25px',
  '&:hover': {
    backgroundColor: theme.palette.grey.grey3,
  },
  zIndex: 1,
}))

const ArrowIconBack = styled(ArrowBackIosOutlined)(({ theme }: any) => ({
  fontSize: '1rem',
  cursor: 'pointer',
  color: theme.palette.common.white,
}))

const ArrowIconForward = styled(ArrowForwardIosOutlined)(({ theme }: any) => ({
  fontSize: '1rem',
  cursor: 'pointer',
  color: theme.palette.common.white,
}))

const StyledOpenAndCloseSideBar = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'useOpenSettingsSidebar',
})<StyledOpenAndClosedProps>(({ useOpenSettingsSidebar, theme }) => ({
  ...(useOpenSettingsSidebar
    ? {
        [theme.breakpoints.down(427)]: {
          width: '426px',
        },
        [theme.breakpoints.down(376)]: {
          width: '375px',
        },
        [theme.breakpoints.down(321)]: {
          width: '320px',
        },
      }
    : {
        [theme.breakpoints.down(426)]: {
          width: '72px',
        },
      }),
}))

const StyledOpenAndClosedContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'useOpenSettingsSidebar',
})<StyledOpenAndClosedProps>(({ useOpenSettingsSidebar, theme }) => ({
  display: 'none',
  [theme.breakpoints.down(426)]: {
    display: 'block',
    padding: '12px 21px',
    position: 'relative',
    height: '54px',
    width: '100%',
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'grab',
      '& > span': {
        borderRadius: '6px',
        boxShadow: `0 3px 16px ${theme.palette.common.black}1a, 0 1px 10px ${theme.palette.background.colored.action}1a, 0 0 0 4px ${theme.palette.background.colored.action}1f`,
      },
    },
  },
  ...(useOpenSettingsSidebar
    ? {
        backgroundColor: `${theme.palette.common.white}`,
      }
    : {
        backgroundColor: theme.palette.grey.grey1,
      }),
}))

const StyledColPrimary = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'useOpenSettingsSidebar' && prop !== 'useMediaQuery',
})<StyledColPrimaryProps>(({ useOpenSettingsSidebar, useMediaQuery, theme }) => ({
  padding: '50px 10px 50px 10px',
  backgroundColor: theme.palette.common.white,
  maxHeight: '100vh',
  height: '100vh',
  overflow: 'auto',
  borderRight: `1px solid ${theme.palette.border.primary}`,
  fontSize: '0.9rem',
  position: 'relative',
  display: 'block',
  ...(useMediaQuery
    ? {
        [theme.breakpoints.down(426)]: {
          display: 'none',
        },
      }
    : !useOpenSettingsSidebar && {
        [theme.breakpoints.down(426)]: {
          display: 'none',
        },
      }),
}))

const StyledColSecondary = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '40px',
  backgroundColor: theme.palette.grey.grey1,
  overflow: 'auto',
  height: '100vh',
  width: '100%',
}))

const StyledIconContainer = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  boxShadow: '0 3px 6px rgba(0,0,0,0.1),0 1px 3px rgba(88, 110, 224, .1),0 0 0 0 transparent',
  height: '30px',
  width: '30px',
  backgroundColor: `${theme.palette.background.colored.action}bf`,
  borderRadius: '6px',
}))

export {
  ArrowIconBack,
  ArrowIconForward,
  StyledClosedButton,
  StyledColPrimary,
  StyledColSecondary,
  StyledIconContainer,
  StyledOpenAndClosedContainer,
  StyledOpenAndCloseSideBar,
}
