import { Button, Grid, Modal, styled } from '@mui/material'

const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const StyledPaper = styled(Grid)(({ theme }) => ({
  width: '400px',
  backgroundColor: theme.palette.common.black,
  border: '1px solid black',
  boxShadow: 'none',
  padding: '20px',
  borderRadius: '4px',
  color: theme.palette.common.white,
  outline: 0,
}))

const StyledLinkBox = styled(Grid)(({ theme }) => ({
  '& > a': {
    color: theme.palette.typography.white,
    paddingRight: '2px',
  },
}))

const StyledImpersonationAlertRoot = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgb(255, 244, 229)',
  color: 'rgb(102, 60, 0)',
  fontSize: '11px',
}))

const StyledImpersonationButton = styled(Button)(() => ({
  fontSize: '11px',
  fontWeight: 600,
}))

export {
  StyledImpersonationAlertRoot,
  StyledImpersonationButton,
  StyledLinkBox,
  StyledModal,
  StyledPaper,
}
