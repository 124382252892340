import { ViewOptionTypeEnum } from '__gql__/graphql'
import { makeVar, ReactiveVar } from '@apollo/client'

export const openSidebar: any = makeVar(false)
export const openSettingsSidebar: any = makeVar(false)
export const selectedTypeFilter: ReactiveVar<string> = makeVar('')
export const libraryViewSelector: ReactiveVar<string> = makeVar('list')
export const personalContentFilter: ReactiveVar<ViewOptionTypeEnum> = makeVar<ViewOptionTypeEnum>(
  ViewOptionTypeEnum.Home,
)
