import { AccountSettingsContentDocument, AccountSettingsContentQuery } from '__gql__/graphql'
import { SignOutFromAllDevicesDocument } from '__gql__/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { Loader } from '@curbside-health-inc/core'
import { useCurrentUser, useLogoutUser } from '@curbside-health-inc/hooks'
import { displayNotification } from '@curbside-health-inc/reactive-vars'
import loadable from '@loadable/component'
import React, { useEffect, useState } from 'react'
import { openSettingsSidebar } from 'ReactiveVars/LibraryStore'
import { muiFiveTheme as theme } from 'Themes/MuiFive'

import Modal from '../../Template'
import { LineDivider, LogoutButton, MenuItemStyled, MenuTitle } from './commonStyled'

interface TabsProps {
  name: string
  occupationData?: AccountSettingsContentQuery['accountSettingsContent']['occupationData']
  disclosuresData?: AccountSettingsContentQuery['accountSettingsContent']['disclosuresData']
}
const activeMenuItem = {
  backgroundColor: `${theme.palette.graphs.con.swatch0}1f`,
  color: theme.palette.graphs.con.swatch0,
  '&:hover': {
    backgroundColor: `${theme.palette.graphs.con.swatch0}1f`,
  },
}

const nonActiveMenuItem = {
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: `${theme.palette.graphs.con.swatch0}0f`,
  },
}

const Tabs = loadable(() => import(/* webpackChunkName: "UserSettingsScreenTabs" */ './Tabs'), {
  resolveComponent: (components, props: TabsProps) => components[props.name],
  cacheKey: (props) => props.name,
  fallback: <Loader variant='custom-screen' />,
})

const TAB_MAP = {
  'My Account': 'MyAccount',
  Disclosures: 'Disclosures',
  Notifications: 'Notifications',
  'Terms Of Use': 'TermsOfUse',
  'Privacy Policy': 'PrivacyPolicy',
  'Content Settings': 'ContentSettings',
}
interface MenuItemsProps {
  activeOption: string
  setActiveOption: (option: string) => void
}
const MenuItems: React.FC<MenuItemsProps> = ({ activeOption, setActiveOption }: MenuItemsProps) => {
  const { logoutUser } = useLogoutUser()

  const [signOutFromAllDevices] = useMutation(SignOutFromAllDevicesDocument, {
    onCompleted() {
      displayNotification('You have been logged out of all devices successfully')
      window.location.href = '/'
    },
    onError(e) {
      displayNotification(e.message, 'error')
    },
  })

  const handleLogoutFromAllDevices = () => {
    const confirmLogout = window.confirm('Are you sure you want to log out from all devices?')
    if (confirmLogout) {
      signOutFromAllDevices()
    }
  }

  return (
    <>
      <MenuTitle>USER SETTINGS</MenuTitle>
      <MenuItemStyled
        onClick={() => {
          setActiveOption('My Account')
          openSettingsSidebar(false)
        }}
        sx={activeOption === 'My Account' ? activeMenuItem : nonActiveMenuItem}
      >
        My Account
      </MenuItemStyled>
      <MenuItemStyled
        onClick={() => {
          setActiveOption('Disclosures')
          openSettingsSidebar(false)
        }}
        sx={activeOption === 'Disclosures' ? activeMenuItem : nonActiveMenuItem}
        data-cy='menu-item-disclosures'
      >
        Disclosures
      </MenuItemStyled>
      <LineDivider />
      <MenuTitle>APP SETTINGS</MenuTitle>
      <MenuItemStyled
        onClick={() => {
          setActiveOption('Notifications')
          openSettingsSidebar(false)
        }}
        sx={activeOption === 'Notifications' ? activeMenuItem : nonActiveMenuItem}
        data-cy='menu-item-notifications'
      >
        Notifications
      </MenuItemStyled>
      <LineDivider />
      <MenuTitle>CONTENT SETTINGS</MenuTitle>
      <MenuItemStyled
        onClick={() => {
          setActiveOption('Content Settings')
          openSettingsSidebar(false)
        }}
        sx={activeOption === 'Content Settings' ? activeMenuItem : nonActiveMenuItem}
        data-cy='menu-item-content-settings'
      >
        Content Settings
      </MenuItemStyled>
      <LineDivider />
      <MenuItemStyled
        onClick={() => {
          setActiveOption('Terms Of Use')
          openSettingsSidebar(false)
        }}
        sx={activeOption === 'Terms Of Use' ? activeMenuItem : nonActiveMenuItem}
      >
        Terms Of Use
      </MenuItemStyled>
      <MenuItemStyled
        onClick={() => {
          setActiveOption('Privacy Policy')
          openSettingsSidebar(false)
        }}
        sx={activeOption === 'Privacy Policy' ? activeMenuItem : nonActiveMenuItem}
      >
        Privacy Policy
      </MenuItemStyled>
      <LineDivider />
      <LogoutButton
        data-cy='logout-button'
        onClick={() => {
          openSettingsSidebar(false)
          logoutUser()
        }}
      >
        Logout
      </LogoutButton>
      <LineDivider />
      <LogoutButton data-cy='logout-all-devices-button' onClick={handleLogoutFromAllDevices}>
        Logout from all devices
      </LogoutButton>
    </>
  )
}

interface UserSettingsScreenType {
  onClose: () => void
  currentTab: string
  openState: boolean
}

const UserSettingsScreen: React.FC<UserSettingsScreenType> = ({
  onClose,
  currentTab,
  openState,
}: UserSettingsScreenType) => {
  const { currentUser } = useCurrentUser()
  const [activeOption, setActiveOption] = useState(currentTab)

  const { data, loading } = useQuery(AccountSettingsContentDocument, {
    skip: !currentUser,
  })

  useEffect(() => {
    if (TAB_MAP[currentTab]) {
      setActiveOption(currentTab)
    }
  }, [currentTab])

  if (loading) {
    return <></>
  }

  return (
    <Modal
      colRatio='1'
      primaryCol={<MenuItems activeOption={activeOption} setActiveOption={setActiveOption} />}
      secondaryCol={
        <React.Suspense fallback={<Loader variant='custom-screen' />}>
          <Tabs
            name={TAB_MAP[activeOption]}
            occupationData={data?.accountSettingsContent.occupationData}
            disclosuresData={data?.accountSettingsContent.disclosuresData}
          />
        </React.Suspense>
      }
      onClose={onClose}
      openState={openState}
      dataCyBtn='user-setting'
    />
  )
}

export default UserSettingsScreen
