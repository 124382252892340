import { Close } from '@mui/icons-material'
import { Fade, Grid } from '@mui/material'
import useIsWebView from 'hooks/useIsWebView'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { matchPath } from 'react-router-dom'
import { StyledLinkBox, StyledModal, StyledPaper } from 'shared/StyledComponents'

const Link: React.FC = () => (
  <a href='https://apps.apple.com/us/app/curbside-health/id1547912169'>download</a>
)

const DownloadAppAlert: React.FC = () => {
  const { isWebView } = useIsWebView()
  const [open, setOpen] = useState(
    isMobile && !isWebView && window._env_.HIDE_DOWNLOAD_APP_ALERT === 'false',
  )
  const { pathname } = useLocation()

  const isPubliclyPublishedContent = matchPath(pathname, {
    path: '/:urlSlug/:id',
    exact: true,
    strict: false,
  })

  return (
    <>
      {open && (
        <StyledModal
          open
          closeAfterTransition
          onClose={() => isPubliclyPublishedContent && setOpen(false)}
          data-testid='download-app-alert-modal'
        >
          <Fade in>
            <StyledPaper item xs={8} sm={5} md={3} lg={2}>
              {isPubliclyPublishedContent && (
                <Grid item xs={12} container justifyContent='flex-end' pb='10px' mb='10px'>
                  <Close onClick={() => setOpen(false)} style={{ fontSize: '11px' }} />
                </Grid>
              )}
              <StyledLinkBox item xs={12}>
                {isPubliclyPublishedContent ? (
                  <>
                    For full functionality of Curbside content please <Link /> the app with link to
                    app store
                  </>
                ) : (
                  <>
                    Please <Link /> the mobile app to use Curbside on your mobile device
                  </>
                )}
              </StyledLinkBox>
            </StyledPaper>
          </Fade>
        </StyledModal>
      )}
    </>
  )
}

export default DownloadAppAlert
