import { Grid, Typography } from '@mui/material'
import Img from 'components/HtmlStyledComponents/Img'
import error500 from 'Images/error_Image.png'
import React from 'react'

const ErrorPage500: React.FC = () => {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} container justifyContent='center' alignItems='center' height='70vh'>
        <Img src={error500} alt='Internal Server Error' height='100%' />
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12} container justifyContent='center'>
          <Typography
            variant='subtitle1'
            fontWeight={600}
            fontFamily='Montserrat'
            color={(theme) => theme.palette.common.black}
          >
            Sorry, something went wrong!
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <Typography variant='caption'>You lost your internet connection.</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ErrorPage500
