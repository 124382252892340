import './App.css'
import 'ckeditor5/ckeditor5.css'

import { ApolloProvider } from '@apollo/client'
import { subscriptionClient as apolloClient } from '@curbside-health-inc/client'
import { EnvWindow } from '@curbside-health-inc/constants'
import { globalTheme } from '@curbside-health-inc/theme'
import { createTheme } from '@material-ui/core/styles'
import { createGenerateClassName } from '@material-ui/core/styles'
import { StylesProvider, ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import { StyledEngineProvider, ThemeProvider as ThemeProviderV5 } from '@mui/material/styles'
import { Provider } from '@rollbar/react'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Configuration } from 'rollbar'
import { MuiFiveGlobalStyles, muiFiveTheme } from 'Themes/MuiFive'

import CurbsideRouter from './routing/Router'

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: 'mui-jss',
})

declare let window: EnvWindow

if (window._env_.ENABLE_APOLLO_DEV_MESSAGES === 'true') {
  // Adds messages only in a dev environment
  import('@apollo/client/dev').then(({ loadDevMessages, loadErrorMessages }) => {
    loadDevMessages()
    loadErrorMessages()
  })
}

// @ts-ignore
const gTheme = createTheme(globalTheme)

const rollbarConfig: Configuration = {
  accessToken: window._env_.ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: window._env_.ROLLBAR_ENV,
  enabled: window._env_.ROLLBAR_ENABLED === 'true',
  ignoredMessages: [/ChunkLoadError(.*)/i],
  payload: {
    client: {
      javascript: {
        code_version: window._env_.VERSION || '1.0.0',
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
}

const App: React.FC = () => (
  <Provider config={rollbarConfig}>
    <ApolloProvider client={apolloClient}>
      <CssBaseline />
      <GlobalStyles styles={MuiFiveGlobalStyles} />
      <Router>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={gTheme}>
            <ThemeProviderV5 theme={muiFiveTheme}>
              <StyledEngineProvider injectFirst>
                <CurbsideRouter />
              </StyledEngineProvider>
            </ThemeProviderV5>
          </ThemeProvider>
        </StylesProvider>
      </Router>
    </ApolloProvider>
  </Provider>
)

export default App
