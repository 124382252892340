import { CurrentUserPolicyDocument } from '__gql__/graphql'
import { useQuery } from '@apollo/client'

const useCurrentUserPolicy = () => {
  const { data } = useQuery(CurrentUserPolicyDocument)

  return data?.currentUserPolicy
}

export default useCurrentUserPolicy
