import { useReactiveVar } from '@apollo/client'
import { Close } from '@mui/icons-material'
import { Grid, Modal, Tooltip, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/system'
import classNames from 'classnames'
import styles from 'GlobalClasses/styles.module.css'
import React from 'react'
import { openSettingsSidebar } from 'ReactiveVars/LibraryStore'

import {
  ArrowIconBack,
  ArrowIconForward,
  StyledClosedButton,
  StyledColPrimary,
  StyledColSecondary,
  StyledIconContainer,
  StyledOpenAndClosedContainer,
  StyledOpenAndCloseSideBar,
} from './StyledComponents'

interface TemplateProps {
  openState: boolean
  primaryCol: any
  secondaryCol: any
  colRatio: string
  onClose: () => void
  dataCyBtn: string
}
const Template: React.FC<TemplateProps> = (props: TemplateProps) => {
  const { openState, primaryCol, secondaryCol, colRatio, onClose, dataCyBtn } = props
  const theme = useTheme()

  const useOpenSettingsSidebar = useReactiveVar(openSettingsSidebar)
  return (
    <Modal
      open={openState}
      onClose={onClose}
      closeAfterTransition
      disableEnforceFocus
      disableRestoreFocus
    >
      <>
        <Grid>
          <StyledClosedButton data-cy={`${dataCyBtn}-close-button`} onClick={onClose}>
            <Close />
          </StyledClosedButton>
        </Grid>
        <Grid container item xs={12}>
          <StyledOpenAndCloseSideBar
            item
            xs={useMediaQuery(theme.breakpoints.down(426)) ? 12 : 3}
            sm={colRatio === '2' ? 5 : 3}
            lg={colRatio === '2' ? 4 : 2}
            xl={colRatio === '2' ? 4 : 2}
            useOpenSettingsSidebar={useOpenSettingsSidebar}
          >
            <StyledOpenAndClosedContainer
              item
              onClick={() => openSettingsSidebar(!useOpenSettingsSidebar)}
              useOpenSettingsSidebar={useOpenSettingsSidebar}
            >
              <Tooltip title='Open Sidebar' placement='right' arrow>
                <StyledIconContainer
                  container
                  alignContent='center'
                  justifyContent='center'
                  item
                  data-cy='side-bar'
                >
                  {!useOpenSettingsSidebar ? <ArrowIconBack /> : <ArrowIconForward />}
                </StyledIconContainer>
              </Tooltip>
              <Grid>
                <StyledClosedButton
                  data-cy={`${dataCyBtn}-close-button-mini`}
                  onClick={onClose}
                  size='small'
                >
                  <Close />
                </StyledClosedButton>
              </Grid>
            </StyledOpenAndClosedContainer>
            <StyledColPrimary
              item
              container
              xs={12}
              useOpenSettingsSidebar={useOpenSettingsSidebar}
              useMediaQuery={useMediaQuery(theme.breakpoints.down(426)) && !useOpenSettingsSidebar}
              className={
                useMediaQuery(theme.breakpoints.down(426)) && !useOpenSettingsSidebar
                  ? classNames(styles.slideLeftContainer)
                  : useOpenSettingsSidebar
                    ? styles.slideRightContainer
                    : ''
              }
            >
              {primaryCol}
            </StyledColPrimary>
          </StyledOpenAndCloseSideBar>
          <StyledColSecondary
            item
            xs={useMediaQuery(theme.breakpoints.down(426)) ? false : 9}
            sm={colRatio === '2' ? 7 : 9}
            lg={colRatio === '2' ? 8 : 10}
            xl={colRatio === '2' ? 8 : 10}
          >
            {!useOpenSettingsSidebar && (
              <Grid item xs={12} width='100%'>
                {secondaryCol}
              </Grid>
            )}
          </StyledColSecondary>
        </Grid>
      </>
    </Modal>
  )
}
export default Template
