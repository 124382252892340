import { CrashApp, Loader } from '@curbside-health-inc/core'
import { useAppLoad, useDatadog } from '@curbside-health-inc/hooks'
import { WebViewContextProvider } from '@curbside-health-inc/reactflow-pathways'
import loadable from '@loadable/component'
import { CssBaseline, Grid } from '@mui/material'
import { ErrorBoundary } from '@rollbar/react'
import ErrorPage500 from 'containers/ErrorPage500'
import useAppOpenEventTrigger from 'hooks/useAppOpenEventTrigger'
import { ConfirmProvider } from 'material-ui-confirm'
import React from 'react'
import { isIE } from 'react-device-detect'
import DbSyncAlert from 'shared/DbSyncAlert'
import DownloadAppAlert from 'shared/DownloadAppAlert'
import ImpersonationAlert from 'shared/ImpersonationAlert'

import NotificationAlert from '../NotificationAlert'

const IERoutes = loadable(() => import(/* webpackChunkName: "IERoutes" */ '../IERoutes'))
const Routes = loadable(() => import(/* webpackChunkName: "Routes" */ './Routes'), {
  fallback: <Loader variant='full-screen' />,
})

const CurbsideRouter: React.FC = () => {
  //@ts-ignore
  const { userLoading, institutionLoading } = useAppLoad()
  useDatadog()
  useAppOpenEventTrigger()

  return userLoading || institutionLoading ? (
    <Loader variant='full-screen' />
  ) : (
    <WebViewContextProvider>
      <ImpersonationAlert />
      <DbSyncAlert />
      <DownloadAppAlert />
      <ConfirmProvider>
        <ErrorBoundary fallbackUI={navigator.onLine ? () => <CrashApp /> : ErrorPage500}>
          <CssBaseline />
          <Grid data-cy='root'>{isIE ? <IERoutes /> : <Routes />}</Grid>
          <NotificationAlert />
        </ErrorBoundary>
      </ConfirmProvider>
    </WebViewContextProvider>
  )
}
export default CurbsideRouter
