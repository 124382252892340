import { MixpanelPlatformEnum } from '__gql__/graphql'
import { useMutation } from '@apollo/client'
import { getMixPanelAdditionalProps } from '@curbside-health-inc/helpers'
import { CALL_MIXPANEL } from '@curbside-health-inc/queries'
import { useCallback } from 'react'

const useMixpanel = () => {
  const [callMixpanel] = useMutation(CALL_MIXPANEL, {})

  return useCallback(
    (
      eventName: string,
      properties: { [key: string]: string | number | string[] | boolean },
      onCompleted?: (res) => void,
    ) => {
      return (
        callMixpanel &&
        callMixpanel({
          variables: {
            event: eventName,
            properties: {
              ...properties,
              ...(document.referrer && { referrer: document.referrer }),
              ...getMixPanelAdditionalProps(),
            },
            platform: MixpanelPlatformEnum.Cecm,
          },
          onCompleted,
        })
      )
    },
    [callMixpanel],
  )
}

export { useMixpanel }
