export const WHITE = '#ffffff'
export const BLACK = '#000000'
export const GREY0 = '#fafafc'
export const GREY1 = '#f6f6f9'
export const GREY2 = '#e6e9eb'
export const GREY3 = '#dddfe5'
export const GREY4 = '#939da8'
export const GREY5 = '#767684'
export const GREY6 = '#545465'
export const GREY7 = '#2b2a35'
export const GREY8 = '#1e1d28'
export const GREY9 = '#020913'
export const GREY10 = '#d3d7db'
export const GREY11 = '#999999'

export const SUCCESS = '#5dce89'
export const ERROR = '#da615d'
export const WARNING = '#ffbb5c'
export const ACTION = '#586ee0'
export const BORDER = '#dddfe5'
export const RED = '#ff7673'
export const RED2 = '#f05050'
export const GREEN = '#33a94b'
export const PURPLE1 = '#8d49f7'
export const PURPLE2 = '#9758fd'
export const BLUE1 = '#6b53ff'
export const BLUE2 = '#4c66f8'
export const GREEN2 = '#21ce99'
export const MKT = '#fafcfc'
export const ERROR_LIGHT = '#f44336'
